import { IDropdownOption } from "@fluentui/react";
import * as React from "react";
import { useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useCustomModelActionCreator } from "../actions/customModel";
import { useFormActionCreator } from "../actions/form";
import { useFormGroupActionCreator } from "../actions/formGroup";
import {
  CannotDeleteExtractorModal,
  useCannotDeleteExtractorModalHandle,
} from "../components/CannotDeleteExtractorModal";
import { ExtractorCreateSection } from "../components/ExtractorCreate";
import { ExtractorCreateModal } from "../components/ExtractorCreateModal";
import {
  ExtractorListSection,
  ExtractorListState,
} from "../components/ExtractorList";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import {
  NavBarLayout,
  useNavBarLayoutBreadcrumbItems,
} from "../components/NavBarLayout";
import { RESTRICTED_PREBUILT_EXTRACTOR_FLAGS } from "../constants";
import { EXTRACTOR_PAGE_SIZE } from "../constants/layout";
import { PrebuiltExtractors } from "../constants/prebuiltExtractor";
import errors, { FOCRError } from "../errors";
import {
  useListenWindowEvent,
  useOnload,
} from "../hooks/asyncguard/asyncguard";
import { useNotifyError } from "../hooks/error";
import { useCreateExtractor, useImportExtractor } from "../hooks/extractor";
import { useExtractorListResource } from "../hooks/extractorListResource";
import { useGtm } from "../hooks/gtm";
import { useAppSelector } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { accessPaginatedAsyncResult } from "../types/asyncResult";
import { ConfirmModalType } from "../types/confirmation";
import {
  Extractor,
  extractorTypeOptions,
  getExtractorHref,
} from "../types/extractor";
import HeaderContainer from "./Header";

type QueryParms = {
  page: number;
  filter: string[];
  search?: string;
};

function getExtractorListQueryParams(): QueryParms {
  const url = new URL(document.location.href);
  const page = parseInt(url.searchParams.get(URLParamsKey.page) || "1") || 1;
  const _filter = url.searchParams.get(URLParamsKey.filterType);
  const filter = _filter ? _filter.split(",") : [];
  const search = url.searchParams.get(URLParamsKey.search) || undefined;
  return { page, filter, search };
}

function useFetchExtractors() {
  const extractorListResource = useExtractorListResource();

  const { getState } = useStore<RootState>();

  const initialExtractorListQueryParams = React.useMemo(
    () => getExtractorListQueryParams(),
    []
  );
  const [filter, _setFilter] = React.useState(
    initialExtractorListQueryParams.filter
  );
  const [search, _setSearch] = React.useState(
    initialExtractorListQueryParams.search
  );
  const [currentPage, setCurrentPage] = React.useState(
    initialExtractorListQueryParams.page
  );

  const refreshCurrentPage = React.useCallback(async () => {
    const state = getState();
    const resourceOwnerId = state.resourceOwner.resourceOwnerId ?? "";
    const { page, filter, search } = getExtractorListQueryParams();

    return extractorListResource.query({
      resourceOwnerId,
      page,
      filter,
      search,
      size: EXTRACTOR_PAGE_SIZE,
    });
  }, [getState, extractorListResource]);

  const { setParam, setParams } = useSearchParamUtils();

  useNotifyError(state => {
    return accessPaginatedAsyncResult(
      state.extractor.paginatedExtractorList
    ).getCurrentError();
  });

  const navigateToPage = React.useCallback(
    (page: number) => {
      setParam(URLParamsKey.page, page.toString(), false);
      setCurrentPage(page);
      refreshCurrentPage();
    },
    [setParam, refreshCurrentPage]
  );

  const setFilter = React.useCallback(
    (filter: string[]) => {
      setParams(
        new Map([
          [URLParamsKey.page, "1"],
          [URLParamsKey.filterType, filter.join(",")],
        ]),
        false
      );
      _setFilter(filter);
      refreshCurrentPage();
    },
    [setParams, refreshCurrentPage]
  );

  const setSearch = React.useCallback(
    (searchString?: string) => {
      setParams(
        new Map([
          [URLParamsKey.page, "1"],
          [URLParamsKey.search, searchString],
        ]),
        false
      );
      _setSearch(searchString);
      refreshCurrentPage();
    },
    [setParams, refreshCurrentPage]
  );

  useListenWindowEvent("popstate", () => {
    refreshCurrentPage();
    const { page, filter, search } = getExtractorListQueryParams();
    _setFilter(filter);
    setCurrentPage(page);
    _setSearch(search);
  });

  useOnload(() => {
    refreshCurrentPage();
  });

  return React.useMemo(
    () => ({
      currentPage,
      navigateToPage,
      filter,
      setFilter,
      refreshCurrentPage,
      search,
      setSearch,
    }),
    [
      currentPage,
      navigateToPage,
      filter,
      setFilter,
      refreshCurrentPage,
      search,
      setSearch,
    ]
  );
}

export default function ExtractorListContainer() {
  const navigate = useNavigate();
  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const { error: notifyError } = useToast();

  const cannotDeleteExtractorModalHandle =
    useCannotDeleteExtractorModalHandle();

  const { removeForm, pinForm, unpinForm } = useFormActionCreator();
  const { deleteFormGroup, pinFormGroup, unpinFormGroup } =
    useFormGroupActionCreator();
  const { deleteCustomModel, pinCustomModel, unpinCustomModel } =
    useCustomModelActionCreator();
  const [isDeletingExtractor, setIsDeletingExtractor] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>(
    getExtractorListQueryParams().search ?? ""
  );

  const {
    extractors,
    pageInfo: { totalCount },
  } = useAppSelector(state => {
    return (
      accessPaginatedAsyncResult(
        state.extractor.paginatedExtractorList
      ).getCurrentResult() ?? {
        extractors: [],
        pageInfo: {
          totalCount: 0,
        },
      }
    );
  });

  const isGettingExtractor = useAppSelector(state => {
    return accessPaginatedAsyncResult(
      state.extractor.paginatedExtractorList
    ).getCurrentIsFetching();
  });

  const {
    currentPage,
    navigateToPage,
    filter,
    setFilter,
    refreshCurrentPage,
    search,
    setSearch,
  } = useFetchExtractors();

  const isFeatureEnabled = useSelector((state: RootState) =>
    state.resourceOwner.isFeatureEnabled()
  );

  const availablePrebuiltExtractors = PrebuiltExtractors.filter(x => {
    const flag = RESTRICTED_PREBUILT_EXTRACTOR_FLAGS.get(x);
    return !flag || isFeatureEnabled(flag);
  });

  const onDropdownChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ): void => {
      if (!option) return;

      let newFilter: string[];

      if (option.key === "select_all") {
        newFilter = option.selected
          ? [...availablePrebuiltExtractors, ...extractorTypeOptions]
          : [];
      } else {
        newFilter = option.selected
          ? [...filter, option.key as string]
          : filter.filter(key => key !== option.key);
      }

      setFilter(newFilter);
    },
    [filter, setFilter, availablePrebuiltExtractors]
  );

  const onSubmitSearch = React.useCallback(() => {
    setSearch(searchText);
  }, [setSearch, searchText]);

  const onClearSearch = React.useCallback(() => {
    setSearchText("");
    setSearch("");
  }, [setSearch]);

  const { pushClickedCreateNewExtractorEvent, pushClickedExtractorEvent } =
    useGtm();

  const onCreate = React.useCallback(() => {
    navigate("/extractor/create");
    pushClickedCreateNewExtractorEvent();
  }, [navigate, pushClickedCreateNewExtractorEvent]);

  const onOpenExtractor = React.useCallback(
    (extractor: Extractor) => {
      navigate(getExtractorHref(extractor));
      pushClickedExtractorEvent(extractor.id);
    },
    [navigate, pushClickedExtractorEvent]
  );

  const onDeleteExtractor = React.useCallback(
    async (extractor: Extractor) => {
      if (
        (await requestUserConfirmation(
          {
            titleId: "extractor.list.confirm_delete.title",
            messageId: "extractor.list.confirm_delete.message",
            actionId: "extractor.list.confirm_delete.action",
            type: ConfirmModalType.Destory,
          },
          false
        )) === false
      ) {
        return;
      }

      setIsDeletingExtractor(true);
      try {
        switch (extractor.resourceType) {
          case "form":
            await removeForm(extractor.id);
            break;
          case "form_group":
            await deleteFormGroup(extractor.id);
            break;
          case "custom_model":
            await deleteCustomModel(extractor.id);
            break;
        }
        refreshCurrentPage();
      } catch (e) {
        if (e instanceof FOCRError) {
          if (e === errors.CannotDeleteExtractorConnectedToWorkspace) {
            cannotDeleteExtractorModalHandle.methods.open();
          } else {
            notifyError(e.messageId);
          }
        } else {
          console.log(e);
          notifyError("extractor.list.error.fail_to_remove_extractor");
        }
      } finally {
        setIsDeletingExtractor(false);
      }
    },
    [
      requestUserConfirmation,
      refreshCurrentPage,
      removeForm,
      deleteFormGroup,
      deleteCustomModel,
      cannotDeleteExtractorModalHandle.methods,
      notifyError,
    ]
  );

  const onPinExtractor = React.useCallback(
    async (extractor: Extractor) => {
      try {
        switch (extractor.resourceType) {
          case "form":
            if (extractor.isPinned) {
              await unpinForm(extractor.id);
            } else {
              await pinForm(extractor.id);
            }
            break;
          case "form_group":
            if (extractor.isPinned) {
              await unpinFormGroup(extractor.id);
            } else {
              await pinFormGroup(extractor.id);
            }
            break;
          case "custom_model":
            if (extractor.isPinned) {
              await unpinCustomModel(extractor.id);
            } else {
              await pinCustomModel(extractor.id);
            }
            break;
        }
      } catch (e) {
        console.log(e);
        notifyError("extractor.list.error.fail_to_pin_extractor");
      }
    },
    [
      pinForm,
      unpinForm,
      pinFormGroup,
      unpinFormGroup,
      pinCustomModel,
      unpinCustomModel,
      notifyError,
    ]
  );

  useListenWindowEvent("popstate", () => {
    const { search } = getExtractorListQueryParams();
    setSearchText(search ?? "");
  });

  const state = React.useMemo((): ExtractorListState => {
    if (isGettingExtractor) {
      return { type: "loading" };
    } else if (totalCount === 0) {
      if (filter.length > 0 || (search ? search.length > 0 : false)) {
        return { type: "noResultFound" };
      } else {
        return { type: "showCreateExtractor" };
      }
    } else {
      return {
        type: "showExtractorGrid",
        extractors,
        totalCount,
        currentPage,
        onOpenExtractor,
        onDeleteExtractor,
        onPinExtractor,
        onNavigateToPage: navigateToPage,
      };
    }
  }, [
    isGettingExtractor,
    filter,
    extractors,
    totalCount,
    currentPage,
    onOpenExtractor,
    onDeleteExtractor,
    onPinExtractor,
    navigateToPage,
    search,
  ]);

  const {
    isCreatingExtractor,
    extractorCreateModalHandle,
    requestToCreateExtractor,
  } = useCreateExtractor();
  const { onImportExtractor, isImportingExtractor } = useImportExtractor();

  const loadingMessage = React.useMemo(() => {
    if (isCreatingExtractor) {
      return "extractor.creating";
    } else if (isDeletingExtractor) {
      return "extractor.list.deleting";
    } else if (isImportingExtractor) {
      return "extractor.importing";
    } else {
      return undefined;
    }
  }, [isCreatingExtractor, isDeletingExtractor, isImportingExtractor]);

  const breadcrumbItems = useNavBarLayoutBreadcrumbItems();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <LoadingModal
          isOpen={
            isDeletingExtractor || isCreatingExtractor || isImportingExtractor
          }
          messageId={loadingMessage}
        />
        <ExtractorCreateModal {...extractorCreateModalHandle.props} />
        <CannotDeleteExtractorModal
          {...cannotDeleteExtractorModalHandle.props}
          goToWorkspacesHref="/workspace"
        />
        <NavBarLayout
          breadcrumbItems={breadcrumbItems}
          tabBarVisible={false}
          tabs={[]}
          selectedTab=""
        >
          {state.type === "showCreateExtractor" ? (
            <ExtractorCreateSection
              onCreateCustomModel={() => {
                requestToCreateExtractor("custom_model");
              }}
              onCreateFixedLayoutExtractor={() => {
                requestToCreateExtractor("form");
              }}
              onCreateCombinedExtractor={() => {
                requestToCreateExtractor("form_group");
              }}
              onCreatePrebuiltExtractor={type => {
                requestToCreateExtractor(type, "");
              }}
              onImport={onImportExtractor}
            />
          ) : (
            <ExtractorListSection
              state={state}
              filter={filter}
              onDropdownChange={onDropdownChange}
              onCreate={onCreate}
              availablePrebuiltExtractors={availablePrebuiltExtractors}
              searchText={searchText}
              setSearchText={setSearchText}
              onSubmitSearch={onSubmitSearch}
              onClearSearch={onClearSearch}
            />
          )}
        </NavBarLayout>
      </Main>
    </Layout>
  );
}
